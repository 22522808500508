@import "~@doct-react/core/build/variables.scss";

$event_card: #ef427e;
$course_card: #00c77a;
$jobs_card: #00a8f2;

.dashboard_cards_bg {
    background-color: $white;
}
.quick_card_height {
    height: 312px;
}

.small-quick_card_height {
    height: 153px;
}

.info_label {
    padding: 6px 12px;

    &.info_label_border_x {
        border-left: 1px solid $white;
        border-right: 1px solid $white;
    }

    .box_border {
        border-width: 2px;
        border-style: solid;

        &.box_border_white {
            border-color: $white;
        }
    }
}

.events_inqury_modal .doct-modal-header .subtitle-1 {
    color: #f4f4f4;
}
.create-event-button-bgcolor {
    background-color: $event_card !important;
}
.jobs-btn-bgcolor {
    background-color: $jobs_card !important;
}
.courses-btn-bgcolor {
    background-color: $course_card !important;
}

.negative-margin-for-btn {
    margin-top: -32px;
}
