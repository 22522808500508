@import "~@doct-react/core/build/variables.scss";

.title_dashed_underline {
    border: 1px dashed black;
    opacity: 12%;
}

.select-apply-options label {
    align-items: flex-start;
}

.text_editor_course .ql-editor {
    overflow: auto;
    max-height: 140px !important;
}

.institute-applications {
    .custom-tabel-row-wrapper {
        &.read {
            background-color: $grey-100;
        }
    }
}
.remove_hover_on_radio_button {
    .MuiRadio-root {
        &:hover {
            background-color: transparent !important;
        }
    }
}
