@import "~@doct-react/core/build/variables.scss";

.rejected_buttons {
    right: 8px;
    bottom: 12px;
}

.delete_course_btn {
    color: $semantic-danger !important;
}

.keep_draft_btn {
    color: $semantic-info !important;
}

.card_action_menu_options div {
    transform: rotate(90deg);
}

.card_action_menu_options {
    & .doct-button.doct-contained-button.doct-secondary-button {
        background-color: rgb(255, 255, 255);
    }
}

.page-content-loader {
    .page-loading {
        background-color: $grey-200 !important;
        width: 44vw !important;
        height: 44vh !important;
    }
}

.no-data-found-screen {
    height: 50vh;
}