@import '~@doct-react/core/build/variables.scss';
.sponsors_section{
    width: 670px;
}

.sponsor_card_body{
    width: 198px !important;
    height: 149px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px #00000008;
}

.img-sponsor{
    border-radius: 6px;
    width: 94px;
    height: 94px;
}

.exhibitor_table_row_company{
    width: 245px;
}
.exhibitor_table_row_booth{
    margin-left: 24px !important;
}
.exhibitor_table_row_city{
    max-width: 160px;  
}

.exhibitors_menu_more_icon{
.doct-button.doct-contained-button.doct-inverse-button {
    background-color: $white !important; 
    transform: rotate(90deg);
}
}
tbody {
    display: block;
    // max-height: 230px;
    overflow: auto;
}
thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table_width {
    width: 40px
}
