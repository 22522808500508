@import '~@doct-react/core/build/variables.scss';

.custom-toster {
  background: #bff1d4;
  border-radius: 8px;
  max-width: 360px;
  width: 100%;
  position: fixed;
  z-index: 9999 + 1;
  display: flex;
  align-items: center;
  padding: 14px 12px;

  .custom-toster-icon {
    width: 36px;
    height: 36px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 12px;

    svg {
      color: #bff1d4;
    }
  }

  &.custom-toster-success {
    background-color: $semantic-success;
    color: $white;


  }

  &.custom-toster-failed {
    background-color: $semantic-danger;
    color: $white;

  }
}