
@import "~@doct-react/core/build/variables.scss";
@import "../../../styles/variable.scss";

$image-input-min-height: 381px;

.image_input {
    display: flex;   
    position: relative; 
    overflow: hidden;
    border-radius: $border-radius;

    &.image_input_initial {
        background-color: $grey-200;
        border: 1px dashed $grey-400;
    }

    &.image_input_fixed_hight {
        min-height: $image-input-min-height;
    }
    
    .image_input_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;   
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    
    .input_while_preview_mode {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .image_input_preview {
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: $image-input-min-height;
        object-fit: cover;
    }

    .image_input_btn_area {
        opacity: 0.92;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;   
    }
}
