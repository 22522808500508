@import "~@doct-react/core/build/variables.scss";

.post_job_text {
    max-width: 278px;
}

.max-min-year-box {
    width: 270px !important;
}

.page-content-loader {
    .page-loading {
        background-color: $grey-200 !important;
        width: 44vw !important;
        height: 44vh !important;
    }
}

.adjust-content-animated-loader {
    top: 46px !important;
}

.skeleton-job-listing-card {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border: 1px dashed rgba(0, 0, 0, 0.2);
    border-radius: 12px;
}

.skeleton-over-text {
    right: 34px;
    top: 42px;
}

.free-job-btn-width {
    width: 134px !important;
    padding: 0 14px !important;
}

.upgrade-plan-button-center {
    bottom: 10%;
}