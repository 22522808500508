@import "~@doct-react/core/build/variables.scss";

.add-edit-form-element-wrapper {
    margin-bottom: 100px;
}

.add-edit-form-element-radius {
    border-radius: 12px;
}

.add-edit-form-element-header {
    position: sticky;
    top: 0;
    z-index: 3;
}

.add-edit-form-element-footer {
    position: sticky;
    bottom: 0;
    z-index: 3;
}

.add-edit-form-element-main-area {
    min-height: calc(100vh - 132px);
}

.form-ui-heading-bg {
    background: rgba($white, 0.4);
}