@import "~@doct-react/core/build/variables.scss";
$background-event-pink: #fe217f12;
$bg-pink-shade: #f300681a;
$bg-light-shade-blue: #00a8f21c;
$bg-light-shade-grey: #00000008;
$bg-green-shade: rgb(30 237 157 / 19%);
$bg-normal-shade-primary: #00a0c075;

.background-event-pink {
    background-color: $background-event-pink;
}
.bg-pink-shade {
    background-color: $bg-pink-shade;
}
.border-radius-6px {
    border-radius: 6px;
}
.bg-light-shade-blue {
    background-color: $bg-light-shade-blue;
}
.bg-light-blue {
    background-color: #4389ff14;
}
.new-applicants-counts {
    width: 231px;
}
.jobs-status-description {
    width: 139px;
    background-color: #00a8f245;
}
.course-status-description {
    width: 139px;
    background-color: #00b8711a;
}
.courses-description {
    background-color: #00b871;
    margin-left: -29px;
}
.jobs-description {
    background-color: #00a8f2;
    margin-left: -29px;
}
.bg-light-shade-grey {
    background-color: $bg-light-shade-grey;
}
.bg-green-shade {
    background-color: $bg-green-shade;
    border: 3px solid white;
}
.text-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 176px;
}
.course_count_width {
    width: 80px;
}
.cursor-pointer-none {
    pointer-events: none !important;
}

.bg-normal-shade-primary {
    background-color: $bg-normal-shade-primary;
}

.user-select-none {
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
}
